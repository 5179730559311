import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  NowITAPICalls,
  NowITUsers,
  OperationalStates,
  OperationalStatus,
  Status,
} from "../util/Types";
import { chain, range } from "lodash";
import { DateTime } from "luxon";
import { Load } from "./util";

type NowITStates = {
  APICalls: NowITAPICalls[];
  Users: NowITUsers[];
  Status: Status<OperationalStatus[]>;
  Tickets: Status<OperationalStatus[]>;
};

const initialState: NowITStates = {
  APICalls: [],
  Users: [],
  Status: {
    value: [],
    uptime: 100,
    status: { status: "NO_DATA", date: "2022-01-01" },
  },
  Tickets: {
    value: [],
    uptime: 100,
    status: { status: "NO_DATA", date: "2022-01-01" },
  },
};

export const NowITSlice = createSlice({
  name: "NowIT",
  initialState,
  reducers: {
    calls: (state, action: PayloadAction<Record<string, number>>) => {
      state.APICalls = Object.entries(action.payload).map(([date, calls]) => ({
        date,
        calls,
      }));
    },
    users: (state, action: PayloadAction<Record<string, number>>) => {
      state.Users = chain(action.payload)
        .toPairs()
        .map((pair) => ({ date: pair[0], users: pair[1] }))
        .value();
    },
    status: (
      state,
      action: PayloadAction<Record<string, OperationalStates>>
    ) => {
      state.Status.value = Object.entries(action.payload).map(
        ([date, status]) => ({ date, status })
      );
      state.Status.status = state.Status.value.at(0);
      state.Status.uptime =
        (state.Status.value.reduce(
          (acc, t) => (t.status === "OPERATIONAL" ? acc + 1 : acc),
          0
        ) /
          state.Status.value.length) *
        100;
      const endDate = DateTime.fromISO(state.Status.value.at(-1).date);
      const emptySpace = range(state.Status.value.length, 90).map((i) => ({
        status: "NO_DATA",
        date: endDate.minus({ days: i }).toISODate().slice(0, 10),
      }));
      state.Status.value = [...emptySpace, ...state.Status.value];
    },
    tickets: (state, action: PayloadAction<Record<string, number>>) => {
      state.Tickets.value = Object.entries(action.payload).map(
        ([date, procent]) => ({
          date,
          status:
            procent > 0.95
              ? "OPERATIONAL"
              : procent > 0.5
              ? "PARTIAL_OUTAGE"
              : "MAJOR_OUTAGE",
        })
      );
      state.Tickets.status = state.Tickets.value.at(-1);
      state.Tickets.uptime =
        (Object.entries(action.payload).reduce(
          (acc, [date, procent]) => acc + procent,
          0
        ) /
          state.Tickets.value.length) *
        100;
      var endDate = DateTime.fromISO(state.Tickets.value.at(-1).date);
      range(state.Tickets.value.length, 12).forEach(
        (i) =>
          (state.Tickets.value = [
            {
              status: "NO_DATA",
              date: endDate.minus({ months: i }).toISODate().slice(0, 7),
            },
            ...state.Tickets.value,
          ])
      );
    },
  },
});

export const fetchNowITData = (token) => async (dispatch) => {
  const url =
    "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com/api/nowit";
  Load(token, dispatch, url + "/calls", calls);
  Load(token, dispatch, url + "/users", users);
  Load(token, dispatch, url + "/status", status);
  Load(token, dispatch, url + "/tickets", tickets);
};

export const { calls, users, status, tickets } = NowITSlice.actions;
export default NowITSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { Load } from "./util";

type Firestore = {
  Date: string;
  Value: number;
};

type SplunkState = {
  index: Firestore[];
  adgroups: Firestore[];
  ingestion: Firestore[];
  internalIndexErrors: Firestore[];
};

const initialState: SplunkState = {
  index: [],
  adgroups: [],
  ingestion: [],
  internalIndexErrors: [],
};

export const SplunkSlice = createSlice({
  name: "Splunk",
  initialState,
  reducers: {
    index: (state, action) => {
      state.index = action.payload;
    },
    adgroups: (state, action) => {
      state.adgroups = action.payload;
    },
    ingestion: (state, action) => {
      state.ingestion = action.payload;
    },
    internalIndexErrors: (state, action) => {
      state.internalIndexErrors = action.payload;
    },
  },
});

export const fetchSplunkData = (token) => async (dispatch) => {
  const url =
    "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com/api/splunk";
  Load(token, dispatch, url + "/index", index);
  Load(token, dispatch, url + "/ad-groups", adgroups);
  Load(token, dispatch, url + "/daily-ingestions", ingestion);
  Load(token, dispatch, url + "/internal-index-errors", internalIndexErrors);
};

export const { index, adgroups, ingestion, internalIndexErrors } =
  SplunkSlice.actions;
export default SplunkSlice.reducer;

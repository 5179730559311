import axios from "axios";

export const Load = async (token, dispatch, url, action) => {
  try {
    const t = await token;
    const h = { headers: { Authorization: "Bearer " + t } };
    const { data } = await axios.get(url, h);
    dispatch(action(data));
  } catch (_) {}
};

import { createSlice } from "@reduxjs/toolkit";

type ModalState = {
  showMain: boolean;
  showGuidance: boolean;
  showProduct: boolean;
};

const initialState: ModalState = {
  showMain: false,
  showGuidance: false,
  showProduct: false,
};

export const modalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    setMainVisibility: (state, action) => {
      state.showMain = action.payload;
    },
    setGuidanceVisibility: (state, action) => {
      state.showGuidance = action.payload;
    },
    setProductVisibility: (state, action) => {
      state.showProduct = action.payload;
    },
  },
});

export const {
  setMainVisibility,
  setGuidanceVisibility,
  setProductVisibility,
} = modalSlice.actions;
export default modalSlice.reducer;

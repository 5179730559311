import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { User, Consumption } from "../util/Types";
import { chain, sumBy } from "lodash";
import { DateTime } from "luxon";
import { Load } from "./util";
type OCMState = {
  loading: boolean;
  users: User[];
  consumptions: Consumption[];
};

const initialState: OCMState = {
  loading: false,
  users: [],
  consumptions: [],
};

export const OCMSlice = createSlice({
  name: "OCM",
  initialState,
  reducers: {
    load: (state, action) => {
      const data = chain(action.payload.employeeData)
        .flatMap((a) => a.EmployeeLicenseCost)
        .map((b) => ({ date: b.date.slice(0, 7), cost: b.cost }))
        .groupBy((c) => c.date)
        .sort(
          (d1, d2) =>
            DateTime.fromISO(d1).toMillis() - DateTime.fromISO(d2).toMillis()
        );
      state.consumptions = data
        .map((x) => ({ key: x[0].date, cost: sumBy(x, (y) => y.cost) }))
        .value();
      state.users = data
        .map((x) => ({ key: x[0].date, amount: x.length }))
        .value();
    },
    loadSU: (state, action) => {
      const data = chain(action.payload)
        .map((x) => x)
        .sort(
          (d1, d2) =>
            DateTime.fromISO(d1.date).toMillis() -
            DateTime.fromISO(d2.date).toMillis()
        );
      state.consumptions = data
        .map((x) => ({ key: x.date.slice(0, 7), cost: x.totalCost }))
        .value();
      state.users = data
        .map((x) => ({ key: x.date.slice(0, 7), amount: x.users }))
        .value();
    },
    loadingOCM: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const fetchData = (token) => async (dispatch) => {
  const url = "https://oi-portal-development-dot-ikea-itsd-ml.appspot.com";
  Load(token, dispatch, url + "/ilert-toc/employees", load);
  dispatch(loadingOCM(false));
};

export const fetchDataSU = (token) => async (dispatch) => {
  const url = "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com";
  Load(token, dispatch, url + "/api/super/ocm", loadSU);
  dispatch(loadingOCM(false));
};

export const { load, loadSU, loadingOCM } = OCMSlice.actions;
export default OCMSlice.reducer;

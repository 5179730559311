import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router";
import Header, { Alternative } from "../../components/NavBars/Links";
import {
  setGuidanceVisibility,
  setProductVisibility,
} from "../../slices/modal";
import { RootState } from "../../store";

import texts from "../../texts/platforms_and_services.json";

const AI = React.lazy(() => import("./Boards/AI"));
const Training = React.lazy(() => import("./Boards/Training"));
const Enablement = React.lazy(() => import("./Boards/Enablement"));
const Observability = React.lazy(() => import("./Boards/Observability"));
const Visualizations = React.lazy(() => import("./Boards/Visualizations"));

const Dashboards = () => {
  const teams = texts.teams;
  const showProduct = useSelector(
    (state: RootState) => state.modal.showProduct
  );
  const showGuidance = useSelector(
    (state: RootState) => state.modal.showGuidance
  );
  const dispatch = useDispatch();
  const alts: Alternative[] = [
    {
      menuVisible: showProduct,
      onClick: () => {
        dispatch(setProductVisibility(true));
      },
      label: "Platforms & Services",
    },
    {
      menuVisible: showGuidance,
      onClick: () => {
        dispatch(setGuidanceVisibility(true));
      },
      label: "Guidance & Support",
    },
  ];
  return (
    <>
      <Header alts={alts} />
      <Routes>
        <Route
          path="observability%20platform"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <Observability
                services={teams[0] !== undefined ? teams[0].services : []}
              />
            </React.Suspense>
          }
        />
        <Route
          path="operational%20visualizations"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <Visualizations
                services={teams[1] !== undefined ? teams[1].services : []}
              />
            </React.Suspense>
          }
        />
        <Route
          path="ai%20operations"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <AI services={teams[2] !== undefined ? teams[2].services : []} />
            </React.Suspense>
          }
        />
        <Route
          path="operational%20enablement"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <Enablement
                services={teams[3] !== undefined ? teams[3].services : []}
              />
            </React.Suspense>
          }
        />
        <Route
          path="operational%20training"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <Training
                services={teams[4] !== undefined ? teams[4].services : []}
              />
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default Dashboards;

import styles from "./styles.module.css";
import Text from "@ingka/text";
import Button from "@ingka/button";
import Header, { Alternative } from "../../components/NavBars/Links";

import texts from "../../texts/landing_page.json";
import { useEffect } from "react";

type Job = {
  date: string;
  title: string;
  url: string;
};

type Jobs = {
  jobs: Job[];
};

const Jobs = ({ jobs }: Jobs) => {
  return (
    <div>
      {jobs.map((job: Job) => (
        <div key={job.title} className={styles.jobContainer}>
          <Text>{job.date}</Text>
          <Text className={styles.job} onClick={() => window.open(job.url)}>
            {job.title}
          </Text>
        </div>
      ))}
    </div>
  );
};

type Card = {
  label: string;
  children?: React.ReactNode;
};

const Card = ({ label, children }: Card) => {
  return (
    <div className={styles.card}>
      <div style={{ width: "100%" }}>
        <Text className={styles.cardLabel} headingSize="s" tagName="h1">
          {label}
        </Text>
      </div>
      {children}
    </div>
  );
};

const Landing = () => {
  const alts: Alternative[] = [
    {
      menuVisible: false,
      onClick: () => {
        window.open("https://www.ingka.com/this-is-ingka-group/");
      },
      label: "About Ingka",
    },
    {
      menuVisible: false,
      onClick: () => {
        window.open("https://join.ingka.com/");
      },
      label: "Job Opportunity",
    },
  ];

  const about = texts.about;
  const jobs = texts.jobs;

  useEffect(() => {
    document.title = "OI Portal - Landing";
  });

  return (
    <div>
      <Header alts={alts} />
      <div className={styles.container}>
        <Card label="Job Opportunity">
          <Jobs jobs={jobs} />
        </Card>
        <img className={styles.logo} src="./oi_logo_white_bg.png" />
        <Card label="About Operational Intelligence">
          {about.map((row, i) => (
            <Text key={i} tagName="p" bodySize="l">
              {row}
            </Text>
          ))}
        </Card>
      </div>
      <Button
        className={styles.button}
        href=""
        text="Contact Us"
        onClick={() => {
          window.open(
            "mailto:oi-portal-public-cont-aaaagrejelvnblgl2bzrfu4f6a@ikea.org.slack.com"
          );
        }}
      />
    </div>
  );
};

export default Landing;

import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import SSRIcon from "@ingka/ssr-icon";
import arrow from "@ingka/ssr-icon/paths/arrow-left";
import Text from "@ingka/text";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setMainVisibility, setProductVisibility } from "../../slices/modal";
import { RootState } from "../../store";
import styles from "./style.module.css";
import Title from "./Title";
import texts from "../../texts/platforms_and_services.json";

const ProductMenu = () => {
  const visible = useSelector((state: RootState) => state.modal.showProduct);
  const teams = texts.teams;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      escapable
      scrollLock
      visible={visible}
      focusLockProps={{
        disabled: false,
      }}
      handleCloseBtn={() => {
        dispatch(setProductVisibility(false));
      }}
    >
      <Sheets
        alignment="left"
        aria-label="Accessibility header for a modal"
        footer={<></>}
        header={<ModalHeader floating />}
      >
        <ModalBody>
          <Title />
          <div
            key={1000}
            className={styles.title}
            onClick={() => {
              dispatch(setProductVisibility(false));
              dispatch(setMainVisibility(true));
            }}
          >
            <SSRIcon key={1001} paths={arrow} />
            <Text
              key={1002}
              className={styles.link}
              tagName="h4"
              headingSize="m"
            >
              {"Main Menu"}
            </Text>
          </div>
          {teams.map((team) => {
            return (
              <div key={team.name}>
                <Text
                  key={team.name}
                  className={styles.link}
                  tagName="h4"
                  headingSize="s"
                  onClick={() => {
                    navigate(`/service/${team.name.toLowerCase()}`);
                    dispatch(setProductVisibility(false));
                  }}
                >
                  {team.name}
                </Text>
                {team.services.map((service, j) => (
                  <Text
                    key={service.name}
                    className={styles.link}
                    style={{ marginLeft: "1rem" }}
                    tagName="p"
                    onClick={() => {
                      navigate(`/service/${team.name.toLowerCase()}`);
                      dispatch(setProductVisibility(false));
                    }}
                  >
                    {service.name}
                  </Text>
                ))}
              </div>
            );
          })}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default ProductMenu;

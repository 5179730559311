import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import Text from "@ingka/text";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  setGuidanceVisibility,
  setMainVisibility,
  setProductVisibility,
} from "../../slices/modal";
import { RootState } from "../../store";
import styles from "./style.module.css";
import Title from "./Title";
import SSRIcon from "@ingka/ssr-icon";
import arrow from "@ingka/ssr-icon/paths/arrow-right";

const MainMenu = () => {
  const visible = useSelector((state: RootState) => state.modal.showMain);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      escapable
      scrollLock
      visible={visible}
      focusLockProps={{
        disabled: false,
      }}
      handleCloseBtn={() => {
        dispatch(setMainVisibility(false));
      }}
    >
      <Sheets
        alignment="left"
        aria-label="Accessibility header for a modal"
        footer={<></>}
        header={<ModalHeader floating />}
      >
        <ModalBody>
          <Title />
          <div
            className={styles.title}
            onClick={() => {
              dispatch(setMainVisibility(false));
              dispatch(setProductVisibility(true));
            }}
          >
            <Text className={styles.link} tagName="h4" headingSize="m">
              {"Platforms & Services"}
            </Text>
            <SSRIcon paths={arrow} />
          </div>
          <div
            className={styles.title}
            onClick={() => {
              dispatch(setMainVisibility(false));
              dispatch(setGuidanceVisibility(true));
            }}
          >
            <Text className={styles.link} tagName="h4" headingSize="m">
              {"Guidance & Support"}
            </Text>
            <SSRIcon paths={arrow} />
          </div>
          <Text tagName="h4" headingSize="s">
            {"Frameworks"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open(
                "https://github.com/ingka-group-digital/oi-o11y-confluence/tree/main/docs"
              );
              dispatch(setMainVisibility(false));
            }}
          >
            {"INGKA Observability Framework"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://automation.ingka.com/");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Automation Frameworks"}
          </Text>
          <Text tagName="h4" headingSize="s">
            {"Tools"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://portal.dev.ingka.com/");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Allen (Developer Portal)"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://portal.catchpoint.com/ ");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Catchpoint"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://data.catalogue.ingka.com/");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Data Catalogue"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://ingka.ilert.com/");
              dispatch(setMainVisibility(false));
            }}
          >
            {"iLert"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://splunk.ikea.com");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Splunk Enterprise"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              window.open("https://ingka.signalfx.com/#/home");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Splunk Observability Cloud (SignalFX)"}
          </Text>
          <Text tagName="h4" headingSize="s">
            {"Dashboards"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/ocm");
              dispatch(setMainVisibility(false));
            }}
          >
            {"On-Call Management"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/iLert");
              dispatch(setMainVisibility(false));
            }}
          >
            {"iLert Health"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/now-it");
              dispatch(setMainVisibility(false));
            }}
          >
            {"NowIT API Health"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/iobserve");
              dispatch(setMainVisibility(false));
            }}
          >
            {"iObserve Health"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/signalfx");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Splunk Observability Health"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => {
              navigate("/dashboard/splunk");
              dispatch(setMainVisibility(false));
            }}
          >
            {"Splunk Health"}
          </Text>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default MainMenu;

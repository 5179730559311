import axios from "axios";
import Wrapper from "./pages/Wrapper";
import TagManager from "react-gtm-module";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchData } from "./slices/offerings";
import { useLocation } from "react-router-dom";

const title = {
  "/": "OI Portal - Home",
  "/dashboard/ocm": "Dashboard - On Call Management",
  "/dashboard/iLert": "Dashboard - iLert Health Metric",
  "/dashboard/iobserve": "Dashboard - iObserve Health Metric",
  "/dashboard/now-it": "Dashboard - NowIT API Health Metric",
  "/dashboard/singalfx": "Dashboard - SignalFX Health Metric",
  "/dashboard/splunk": "Dashboard - SPLUNK Health Metric",
  "/service/ai%20operations": "Services - AI Operations",
  "/service/operational%20training": "Services - Operational Training",
  "/service/operational%20enablement": "Services - Operational Enablement",
  "/service/observability%20platform": "Services - Observability Platform",
  "/service/operational%20visualizations":
    "Services - Operational Visualizations",
};

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState({
    gtmId: process.env.REACT_APP_GA_TRACKING_ID,
    dataLayer: {
      userId: "NO_USER_ID",
    },
  });
  useEffect(() => {
    dispatch(fetchData(getAccessTokenSilently()));
  }, []);

  useEffect(() => {
    const setID = async () => {
      try {
        const url =
          "https://iobserve-backend-dot-ikea-itsd-ml.appspot.com" +
          `/aiops/iobserve/ga/getUserDetails?email=${user.email}`;
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(url, { headers });
        if (res.status === 200 && !res.data.userID.includes("undefined")) {
          const newState = {
            gtmId: state.gtmId,
            dataLayer: { userId: res.data.userID },
          };
          setState(newState);
          TagManager.initialize(newState);
          setInitialized(true);
          TagManager.dataLayer({
            dataLayer: {
              event: "page_view_with_id",
              path: location.pathname,
              userId: newState.dataLayer.userId,
            },
          });
        }
      } catch (_) {}
    };

    document.title = title[location.pathname] ?? "OI Portal";

    if (isAuthenticated) {
      if (initialized) {
        TagManager.dataLayer({
          dataLayer: {
            event: "page_view_with_id",
            path: location.pathname,
            userId: state.dataLayer.userId,
          },
        });
      } else {
        setID();
      }
    }
  }, [location, isAuthenticated]);

  return <Wrapper />;
};
export default App;

import React from "react";
import { Routes, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import {
  setGuidanceVisibility,
  setProductVisibility,
} from "../../slices/modal";
import Header, { Alternative } from "../../components/NavBars/Links";

const OCM = React.lazy(() => import("./Boards/OCM"));
const NowIT = React.lazy(() => import("./Boards/NowIT"));
const ILert = React.lazy(() => import("./Boards/iLert"));
const SPLUNK = React.lazy(() => import("./Boards/Splunk"));
const IObserve = React.lazy(() => import("./Boards/iObserve"));
const SignalFX = React.lazy(() => import("./Boards/SignalFX"));

const Dashboards = () => {
  const dispatch = useDispatch();
  const showProduct = useSelector(
    (state: RootState) => state.modal.showProduct
  );
  const showGuidance = useSelector(
    (state: RootState) => state.modal.showGuidance
  );

  const alts: Alternative[] = [
    {
      menuVisible: showProduct,
      onClick: () => {
        dispatch(setProductVisibility(true));
      },
      label: "Platforms & Services",
    },
    {
      menuVisible: showGuidance,
      onClick: () => {
        dispatch(setGuidanceVisibility(true));
      },
      label: "Guidance & Support",
    },
  ];
  return (
    <>
      <Header alts={alts} />
      <Routes>
        <Route
          path="ocm"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <OCM />
            </React.Suspense>
          }
        />
        <Route
          path="iLert"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <ILert />
            </React.Suspense>
          }
        />
        <Route
          path="now-it"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <NowIT />
            </React.Suspense>
          }
        />
        <Route
          path="iobserve"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <IObserve />
            </React.Suspense>
          }
        />
        <Route
          path="signalfx"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <SignalFX />
            </React.Suspense>
          }
        />
        <Route
          path="splunk"
          element={
            <React.Suspense fallback={<>...Loading</>}>
              <SPLUNK />
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default Dashboards;

import styles from "./styles.module.css";
import Header, { Alternative } from "../../components/NavBars/Links";
import Text from "@ingka/text";
import Button from "@ingka/button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setGuidanceVisibility,
  setProductVisibility,
} from "../../slices/modal";

import texts from "../../texts/home_page.json";

type Board = { children: React.ReactNode };
const Board = ({ children }: Board) => {
  return <div className={styles.boardContainer}>{children}</div>;
};

const Home = () => {
  const dispatch = useDispatch();
  const title = texts.title;
  const body = texts.body;
  const showProduct = useSelector(
    (state: RootState) => state.modal.showProduct
  );
  const showGuidance = useSelector(
    (state: RootState) => state.modal.showGuidance
  );
  const alts: Alternative[] = [
    {
      menuVisible: showProduct,
      onClick: () => {
        dispatch(setProductVisibility(true));
      },
      label: "Platforms & Services",
    },
    {
      menuVisible: showGuidance,
      onClick: () => {
        dispatch(setGuidanceVisibility(true));
      },
      label: "Guidance & Support",
    },
  ];

  useEffect(() => {
    document.title = "OI Portal - Home";
  });

  return (
    <div className={styles.container}>
      <Header alts={alts} />
      <Board>
        <div className={styles.infoContainer}>
          <Text tagName="h1" headingSize="l">
            {title}
          </Text>
          <Text tagName="p" bodySize="m">
            {body}
          </Text>
          <Button
            className={styles.button}
            text="Platform & Services"
            type="primary"
            onClick={() => dispatch(setProductVisibility(true))}
          />
        </div>
        <img className={styles.img} src="./oi_home_.png" />
      </Board>
    </div>
  );
};
export default Home;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type GAState = {
  gtmId: string;
  userId: string;
  initialize: boolean;
};

const initialState: GAState = {
  gtmId: "GTM-XXXXXXX",
  userId: "NOT_LOGGED_IN",
  initialize: false,
};

export const GASlice = createSlice({
  name: "GA",
  initialState,
  reducers: {
    SetGTMID: (state, action) => {
      state.gtmId = action.payload;
    },
    addUserID: (state, action) => {
      state.userId = action.payload;
    },
    initialize: (state) => {
      state.initialize = true;
    },
  },
});

export const fetchIDForGTM = (tokenPromise, email) => async (dispatch) => {
  try {
    const url =
      "https://iobserve-backend-dot-ikea-itsd-ml.appspot.com" +
      `/aiops/iobserve/ga/getUserDetails?email=${email}`;
    const token = await tokenPromise;
    const headers = { Authorization: `Bearer ${token}` };
    const res = await axios.get(url, { headers });
    if (res.status === 200 && !res.data.userID.includes("undefined")) {
      dispatch(addUserID(res.data.userID));
    }
  } catch (_) {}
};

export const { SetGTMID, addUserID, initialize } = GASlice.actions;
export default GASlice.reducer;

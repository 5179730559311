import { configureStore } from "@reduxjs/toolkit";

import OCMReducer from "./slices/OCM";
import modalReducer from "./slices/modal";
import ILertReducer from "./slices/iLert";
import NowITReducer from "./slices/NowIT";
import SplunkReducer from "./slices/Splunk";
import iObserveReducer from "./slices/iObserve";
import signalFXReducer from "./slices/signalFX";
import GAReducer from "./slices/GoogleAnalytics";
import offeringsReducer from "./slices/offerings";

export const store = configureStore({
  reducer: {
    GA: GAReducer,
    ocm: OCMReducer,
    modal: modalReducer,
    ilert: ILertReducer,
    nowIT: NowITReducer,
    splunk: SplunkReducer,
    iObserve: iObserveReducer,
    signalFX: signalFXReducer,
    offerings: offeringsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

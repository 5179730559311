import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Team } from "../util/Types";
import texts from "../texts/platforms_and_services.json";

type OfferingsState = {
  teams: Team[];
  services: string[][];
  searchAlternatives: string[][];
  startAlternatives: string[][];
  selectedTeam: string;
  selectedService: string;
};

const initialState: OfferingsState = {
  teams: [],
  services: [],
  searchAlternatives: [],
  startAlternatives: [],
  selectedTeam: "",
  selectedService: "",
};

export const offeringsSlice = createSlice({
  name: "Offerings",
  initialState,
  reducers: {
    load: (state, action: PayloadAction<{ teams: Team[] }>) => {
      state.teams = action.payload.teams;
      state.services = state.teams.reduce<string[][]>(
        (acc, team) => [
          ...acc,
          ...team.services.map((service) => [service.name, team.name]),
        ],
        []
      );
      state.searchAlternatives = [
        state.services[0],
        state.services[8],
        state.services[6],
        state.services[17],
        state.services[12],
      ];
      state.startAlternatives = state.searchAlternatives;
    },
    input: (state, { payload }) => {
      state.searchAlternatives =
        payload === ""
          ? state.startAlternatives
          : state.services
              .filter((value) =>
                value[0].toLowerCase().includes(payload.toLowerCase())
              )
              .slice(0, 5);
    },
    select: (state, { payload }) => {
      state.selectedTeam = payload.team;
      state.selectedService = payload.service;
    },
    clear: (state) => {
      state.searchAlternatives = state.startAlternatives;
    },
    search: (state) => {
      if (state.searchAlternatives.length === 0) {
        state.selectedService = "";
        state.selectedTeam = "";
      } else {
        state.selectedService = state.searchAlternatives[0][0];
        state.selectedTeam = state.searchAlternatives[0][1];
      }
    },
  },
});

export const fetchData = (tokenPromise) => async (dispatch) => {
  dispatch(load(texts));
};

export const { load, input, clear, select, search } = offeringsSlice.actions;
export default offeringsSlice.reducer;

import styles from "./styles.module.css";

export type Alternative = {
  label: string;
  onClick: () => void;
  menuVisible: boolean;
};

type Header = {
  alts: Alternative[];
};

const Header = ({ alts }: Header) => {
  return (
    <div className={styles.header}>
      {alts.map((alt) => {
        return (
          <div
            key={alt.label}
            className={styles.alt}
            onClick={() => alt.onClick()}
          >
            <b>{alt.label}</b>
          </div>
        );
      })}
    </div>
  );
};

export default Header;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/loading/dist/style.css";
import "@ingka/text/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/table/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/list/dist/style.css";

import App from "./App";
import Auth0 from "./components/Auth0";
import { store } from "./store";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0>
        <Provider store={store}>
          <App />
        </Provider>
      </Auth0>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

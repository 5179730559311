import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  iObserveEngagement,
  iObserveVisitors,
  OperationalStatus,
  Status,
} from "../util/Types";

import { sortBy, range, chain } from "lodash";
import { DateTime } from "luxon";
import { Load } from "./util";

type iObserveStates = {
  Status: Status<OperationalStatus[]>;
  Visitors: iObserveVisitors[];
  Engagement: iObserveEngagement[];
};

const initialState: iObserveStates = {
  Status: {
    value: [],
    uptime: 100,
    status: { status: "NO_DATA", date: "2022-01-01" },
  },
  Visitors: [],
  Engagement: [],
};

export const iObserveSlice = createSlice({
  name: "iObserve",
  initialState,
  reducers: {
    loadStatus: (state, action) => {
      let status = {} as Status<OperationalStatus[]>;
      const data = action.payload;
      status.uptime = data.at(-1).uptime;
      status.status = {
        status:
          data.at(-1).uptime > 99.5
            ? "OPERATIONAL"
            : status.uptime > 10
            ? "PARTIAL_OUTAGE"
            : "MAJOR_OUTAGE",
        date: data.at(-1).date.slice(0, 10),
      };
      status.value = data.map((x) => ({
        status:
          x.uptime === 0
            ? "NO_DATA"
            : x.uptime > 99.5
            ? "OPERATIONAL"
            : x.uptime > 10
            ? "PARTIAL_OUTAGE"
            : "MAJOR_OUTAGE",

        date: x.date.slice(0, 10),
      }));
      state.Status = status;
      const endDate = DateTime.fromISO(state.Status.value.at(-1).date);
      range(state.Status.value.length, 90).forEach(
        (i) =>
          (state.Status.value = [
            {
              status: "NO_DATA",
              date: endDate.minus({ days: i }).toISODate().slice(0, 10),
            },
            ...state.Status.value,
          ])
      );
    },
    loadVisitors: (state, action) => {
      const visitors = chain(action.payload)
        .map((x) => ({
          value: x.visitors,
          date: x.date.slice(0, 10),
        }))
        .groupBy((x) => x.date.slice(0, 7))
        .map((x) => {
          return x.reduce(
            (acc, y) => ({
              value: acc.value + y.value,
              date: y.date.slice(0, 7),
            }),
            { value: 0, date: x.date }
          );
        })
        .value();
      state.Visitors = visitors;
    },
    loadEngagement: (state, action) => {
      const engagement = chain(action.payload)
        .map((x) => ({
          value: x.engagement,
          date: x.date.slice(0, 10),
        }))
        .groupBy((x) => x.date.slice(0, 7))
        .map((x) => {
          return x.reduce(
            (acc, y) => ({
              value: Math.round((acc.value + y.value) * 1000) / 1000,
              date: y.date.slice(0, 7),
            }),
            { value: 0, date: x.date }
          );
        })
        .value();
      state.Engagement = engagement;
    },
  },
});

export const fetchIObserveData = (tokenPromise) => async (dispatch) => {
  const url =
    "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com/api/iObserve";
  Load(tokenPromise, dispatch, url + "/status", loadStatus);
  Load(tokenPromise, dispatch, url + "/visitors", loadVisitors);
  Load(tokenPromise, dispatch, url + "/engagement", loadEngagement);
};

export const { loadStatus, loadVisitors, loadEngagement } =
  iObserveSlice.actions;
export default iObserveSlice.reducer;

import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import Text from "@ingka/text";
import arrow from "@ingka/ssr-icon/paths/arrow-left";
import SSRIcon from "@ingka/ssr-icon";
import { useDispatch, useSelector } from "react-redux";
import { setGuidanceVisibility, setMainVisibility } from "../../slices/modal";
import { RootState } from "../../store";
import styles from "./style.module.css";
import Title from "./Title";

const GuidanceMenu = () => {
  const visible = useSelector((state: RootState) => state.modal.showGuidance);
  const dispatch = useDispatch();
  return (
    <Modal
      escapable
      scrollLock
      visible={visible}
      focusLockProps={{
        disabled: false,
      }}
      handleCloseBtn={() => {
        dispatch(setGuidanceVisibility(false));
      }}
    >
      <Sheets
        alignment="left"
        aria-label="Accessibility header for a modal"
        footer={<></>}
        header={<ModalHeader floating />}
      >
        <ModalBody>
          <Title />
          <div
            className={styles.title}
            onClick={() => {
              dispatch(setGuidanceVisibility(false));
              dispatch(setMainVisibility(true));
            }}
          >
            <SSRIcon paths={arrow} />
            <Text tagName="h4" headingSize="m" className={styles.link}>
              {"Main Menu"}
            </Text>
          </div>
          <Text tagName="h4" headingSize="s">
            {"Frameworks"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://github.com/ingka-group-digital/oi-o11y-confluence/tree/main/docs"
              )
            }
          >
            {"INGKA Observability Framework"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => window.open("https://automation.ingka.com/")}
          >
            {"Automation Frameworks"}
          </Text>
          <Text
            tagName="h4"
            headingSize="s"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/Observability+101"
              )
            }
          >
            {"Guidance"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=234490010"
              )
            }
          >
            {"SLI & SLO"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/OpenTelemetry"
              )
            }
          >
            {"OpenTelemetry"}
          </Text>
          <Text
            className={styles.deadLink}
            style={{ marginLeft: "1rem" }}
            tagName="p"
          >
            {"Implementation Patterns"}
          </Text>
          <Text
            className={styles.deadLink}
            style={{ marginLeft: "1rem" }}
            tagName="p"
          >
            {"Use Cases"}
          </Text>
          <Text
            className={styles.deadLink}
            style={{ marginLeft: "1rem" }}
            tagName="p"
          >
            {"Templates"}
          </Text>
          <Text tagName="h4" headingSize="s">
            {"Documentation"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/Catchpoint+as+a+Service"
              )
            }
          >
            {"Catchpoint"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/Ilert"
              )
            }
          >
            {"iLert"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://github.com/ingka-group-digital/oi-o11y-confluence/tree/main/docs/🔎_OpenTelemetry"
              )
            }
          >
            {"OpenTelemetry"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/ServiceTrace+as+a+Service"
              )
            }
          >
            {"Service Trace"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=248211886"
              )
            }
          >
            {"Splunk Enterprise"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() =>
              window.open(
                "https://confluence.build.ingka.ikea.com/display/ITOI/Splunk+Observability+%28SignalFX%29+as+a+Service"
              )
            }
          >
            {"Splunk Observability Cloud (SignalFX)"}
          </Text>
          <Text className={styles.deadLink} tagName="h4" headingSize="s">
            {"Support"}
          </Text>
          {/* <Text
            className={styles.deadLink}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => window.open("")}
          >
            {"Slack"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => window.open("")}
          >
            {"Create Ticket"}
          </Text> */}
          <Text className={styles.deadLink} tagName="h4" headingSize="s">
            {"Contact Us"}
          </Text>
          {/* <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => window.open("")}
          >
            {"Meet Our Teams"}
          </Text>
          <Text
            className={styles.link}
            style={{ marginLeft: "1rem" }}
            tagName="p"
            onClick={() => window.open("")}
          >
            {"Provide Feedback"}
          </Text> */}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default GuidanceMenu;

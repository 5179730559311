import { useDispatch, useSelector } from "react-redux";
import { clear, input } from "../../../slices/offerings";
import styles from "./styles.module.css";
import Search from "@ingka/search";
import { useState } from "react";
import { RootState } from "../../../store";
import { useNavigate } from "react-router";
import Text from "@ingka/text";
import magnify from "@ingka/ssr-icon/paths/magnifying-glass";
import SSRIcon from "@ingka/ssr-icon";

const servicePaths = {
  "Observability Platform": "/service/observability%20platform",
  "AI Operations": "/service/ai%20operations",
  "Operational Visualizations": "/service/operational%20visualizations",
  "Operational Training": "/service/operational%20training",
  "Operational Enablement": "/service/operational%20enablement",
};

const MySearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchAlts = useSelector(
    (state: RootState) => state.offerings.searchAlternatives
  );
  const [showSearchModal, setSearchModal] = useState(false);
  return (
    <div>
      <div className={styles.container}>
        <Search
          id="oi-search"
          placeholder="What are you looking for?"
          onBlur={(event) => (event.target.value = "")}
          onKeyUp={(event) => {
            switch (event.key) {
              case "Enter":
                setSearchModal(false);
                navigate(servicePaths[searchAlts[0][1]]);
                event.target.value = "";
                break;
              case "Escape":
                setSearchModal(false);
                break;
            }
          }}
          onSearch={(event) => {
            setSearchModal(false);
            navigate(servicePaths[searchAlts[0][1]]);
            event.target.value = "";
          }}
          onInput={(event) => {
            dispatch(input((event.target as HTMLInputElement).value));
            setSearchModal(true);
          }}
          onClear={(event) => {
            setSearchModal(false);
            event.target.value = "";
            dispatch(clear());
          }}
          className={styles.search}
        />
        {showSearchModal ? (
          <div className={styles.searchBg}>
            {searchAlts.map((alt) => (
              <div
                key={alt[0]}
                className={styles.searchCard}
                onClick={() => {
                  setSearchModal(false);
                  navigate(servicePaths[alt[1]]);
                }}
              >
                <SSRIcon paths={magnify} />
                <Text tagName="h4" bodySize="l">
                  {alt[0]}
                </Text>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      {showSearchModal ? (
        <div className={styles.modal} onClick={() => setSearchModal(false)} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MySearch;

import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../home";
import Landing from "../Landing";
import Service from "../Service";
import Dashboard from "../Dashboards";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "../../components/NavBars/Header";

type Props = { children: JSX.Element };
const AuthWrapper = ({ children }: Props) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const Wrapper = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div style={{ minWidth: "850px" }}>
      <Navbar />
      <Routes>
        <Route path="/" element={isAuthenticated ? <Home /> : <Landing />} />
        <Route
          path="/dashboard/*"
          element={
            <AuthWrapper>
              <Dashboard />
            </AuthWrapper>
          }
        />
        <Route
          path="/service/*"
          element={
            <AuthWrapper>
              <Service />
            </AuthWrapper>
          }
        />
      </Routes>
    </div>
  );
};

export default Wrapper;

import axios from "axios";
import { DateTime } from "luxon";
import { createSlice } from "@reduxjs/toolkit";
import { range } from "lodash";

import { OperationalStatus } from "../util/Types";
import { Load } from "./util";

type RunMetrics = {
  Uptime: number;
  Status: OperationalStatus;
  Metrics: OperationalStatus[];
};

type Ticket = {
  date: string;
  value: number;
};
type Users = {
  date: string;
  value: number;
};

type iLertStates = {
  API: RunMetrics;
  Reporting: RunMetrics;
  Operational: RunMetrics;
  EventIngestion: RunMetrics;
  NotificationDelivery: RunMetrics;
  TicketSupport: RunMetrics;
  Tickets: Ticket[];
  Users: Users[];
  ADUsers: Users[];
};

const initialState: iLertStates = {
  API: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  Reporting: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  Operational: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  EventIngestion: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  NotificationDelivery: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  TicketSupport: {
    Status: { status: "NO_DATA", date: "2022-01-01" },
    Uptime: 0,
    Metrics: [],
  },
  Tickets: [],
  Users: [],
  ADUsers: [],
};

export const ILertSlice = createSlice({
  name: "iLert",
  initialState,
  reducers: {
    load: (state, action) => {
      const emptySpaces = range(90 - action.payload.length).map((i) => ({
        status: "NO_DATA",
        date: DateTime.fromISO(action.payload.at(-2).date)
          .minus({ day: i + 1 })
          .toISODate(),
      }));

      state.Operational.Status = {
        status: action.payload.at(0).status,
        date: action.payload.at(0).date.slice(0, 10),
      };
      state.Operational.Metrics = action.payload
        .map((x) => ({ status: x.status, date: x.date.slice(0, 10) }))
        .concat(emptySpaces)
        .reverse();
      state.Operational.Uptime =
        (action.payload.reduce(
          (acc, x) => (x.status === "OPERATIONAL" ? acc + 1 : acc),
          0
        ) /
          action.payload.length) *
        100;

      const services = action.payload.slice(1);
      state.API = getServiceStatus(4, services, emptySpaces);
      state.Reporting = getServiceStatus(7, services, emptySpaces);
      state.EventIngestion = getServiceStatus(0, services, emptySpaces);
      state.NotificationDelivery = getServiceStatus(1, services, emptySpaces);
    },
    users: (state, action) => {
      state.Users = action.payload.map((x) => ({
        ...x,
        Value: Math.round(x.Value),
      }));
    },
    adUsers: (state, action) => {
      state.ADUsers = action.payload.map((x) => ({
        ...x,
        Value: Math.round(x.Value),
      }));
    },
    supportTicket: (state, action) => {
      const getStatus = (x: number) =>
        x >= 95 ? "OPERATIONAL" : x >= 50 ? "PARTIAL_OUTAGE" : "MAJOR_OUTAGE";
      const x = action.payload.at(0);
      action.payload = action.payload.slice(1); // <- Removes head of list!
      state.TicketSupport.Status = {
        status: getStatus(x.Value),
        date: "0000-00-00",
      };
      const tickets = action.payload.map((ticket) => ({
        status: getStatus(ticket.Value),
        date: ticket.Date,
      }));
      const emptySpaces = range(12 - action.payload.length)
        .map((i) => ({
          status: "NO_DATA",
          date: DateTime.fromISO(x.Date)
            .minus({ month: i + 1 })
            .toISODate()
            .slice(0, 7),
        }))
        .reverse();
      state.TicketSupport.Metrics = [...emptySpaces, ...tickets];
      state.TicketSupport.Uptime =
        action.payload.reduce((acc, ticket) => {
          return acc + ticket.Value;
        }, 0) / action.payload.length;
    },
    volumeTicket: (state, action) => {
      state.Tickets = action.payload;
    },
  },
});

const getServiceStatus = (i, payload, emptySpaces): RunMetrics => {
  const Status = {
    status: payload.at(-2).services.at(i).status,
    date: payload.at(-2).date.slice(0, 10),
  };
  const Metrics = payload
    .map((x) => ({
      status: x.services.at(i).status,
      date: x.date.slice(0, 10),
    }))
    .concat(emptySpaces)
    .reverse();
  const Uptime =
    (payload.reduce(
      (acc, x) => (x.services.at(i).status === "OPERATIONAL" ? acc + 1 : acc),
      0
    ) /
      payload.length) *
    100;
  return { Status, Uptime, Metrics };
};

export const getDataILertData = (token) => async (dispatch) => {
  const url =
    "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com/api/iLert";
  Load(token, dispatch, url + "/users", users);
  Load(token, dispatch, url + "/summary", load);
  Load(token, dispatch, url + "/adusers", adUsers);
  Load(token, dispatch, url + "/ticket", volumeTicket);
  Load(token, dispatch, url + "/ticket/supported", supportTicket);
};

export const { load, users, adUsers, volumeTicket, supportTicket } =
  ILertSlice.actions;
export default ILertSlice.reducer;

import styles from "./styles.module.css";
import menu from "@ingka/ssr-icon/paths/menu";
import person from "@ingka/ssr-icon/paths/person";
import allen from "@ingka/ssr-icon/paths/allen-key";
import arrow from "@ingka/ssr-icon/paths/arrow-right";
import SSRIcon from "@ingka/ssr-icon";
import Text from "@ingka/text";
import Search from "./Search";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import MainMenu from "../../Modal/MainMenu";
import GuidanceMenu from "../../Modal/GuidanceMenu";
import ProductMenu from "../../Modal/ProductMenu";
import { setMainVisibility } from "../../../slices/modal";
import { ReactComponent as OILogo } from "../../../oi_logo.svg";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const IconButton = ({ onClick }: Props) => {
  return (
    <button onClick={onClick} className={styles.loginButton}>
      <SSRIcon className={styles.loginIcon} paths={person} />
    </button>
  );
};

const Navbar = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  return (
    <div className={styles.header}>
      <MainMenu />
      <GuidanceMenu />
      <ProductMenu />
      <div className={styles.left}>
        {isAuthenticated ? (
          <SSRIcon
            className={styles.menu}
            paths={menu}
            onClick={() => dispatch(setMainVisibility(true))}
          />
        ) : (
          <div className={styles.menu} />
        )}
        <OILogo className={styles.oiLogo} onClick={() => navigate("/")} />
        <div className={styles.OI} onClick={() => navigate("/")}>
          Operational Intelligence
        </div>
      </div>
      <div className={styles.center}>
        {isAuthenticated ? <Search /> : <div className={styles.search} />}
      </div>
      <div className={styles.right}>
        {isAuthenticated ? (
          <SSRIcon
            className={styles.allen}
            paths={allen}
            onClick={() => {
              window.open("https://portal.dev.ingka.com/");
            }}
          />
        ) : (
          <div className={styles.allen} />
        )}
        {!isAuthenticated ? (
          <div className={styles.loginWrapper}>
            <Text className={styles.login}>Sign in</Text>
            <SSRIcon className={styles.login} paths={arrow} />
          </div>
        ) : (
          <div className={styles.allen} />
        )}
        <IconButton onClick={() => loginWithRedirect()} />
      </div>
    </div>
  );
};

export default Navbar;

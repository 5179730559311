import { ReactComponent as OILogo } from "../../oi_logo.svg";
import Text from "@ingka/text";
import styles from "./style.module.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  setGuidanceVisibility,
  setMainVisibility,
  setProductVisibility,
} from "../../slices/modal";

const Title = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className={styles.title}
      onClick={() => {
        dispatch(setMainVisibility(false));
        dispatch(setGuidanceVisibility(false));
        dispatch(setProductVisibility(false));
        navigate("/");
      }}
    >
      <OILogo className={styles.icon} />
      <Text className={styles.link} tagName="h4" headingSize="m">
        {"[Operational Intelligence]"}
      </Text>
    </div>
  );
};

export default Title;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Load } from "./util";

type SignalFXUser = {
  date: string;
  value: number;
};

type SignalFXState = {
  users: SignalFXUser[];
};

const initialState: SignalFXState = {
  users: [],
};

export const SignalFXSlice = createSlice({
  name: "SignalFX",
  initialState,
  reducers: {
    consumers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const fetchSignalFXData = (token) => async (dispatch) => {
  Load(
    token,
    dispatch,
    "https://oi-portal-backend-dot-ikea-itsd-ml.appspot.com/api/signalfx/user",
    consumers
  );
};

export const { consumers } = SignalFXSlice.actions;
export default SignalFXSlice.reducer;
